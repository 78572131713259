.fondo{
    background-image: linear-gradient(
          90deg,
          rgb(21, 21, 21),
          rgba(32, 32, 32, 0.6)
          ),
          url("https://upload.wikimedia.org/wikipedia/commons/1/16/Nevado_Huascar%C3%A1n_%28south_view%29.jpg");
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
  
   }     